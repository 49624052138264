import history from '../history';
import logger from '../logger';
import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  DELETE_ALL_MESSAGES,
  SET_MESSAGE,
  LOADING_UI,
  SET_AIRCRAFT,
  SET_SELECTED_ACCOUNT,
} from './types';
import ggDASHConfig from '../ggDASHConfig';
import {
  apiGetInternalAccounts,
  apiGetAircraft,
  apiGetAircraftView,
  apiForgotPassword,
} from './api';
import indexListBy from '../lib/indexListBy';

const isSuccess = status => status === 200 || status === 201;
const isError = status => status !== 404 && status !== 401 && status !== 403;

export const loginAs = (name, accountList, token, email, cob_unverified) => async dispatch => {
  ggDASHConfig.currentUser = name;
  const accountAppLength = accountList && Object.keys(accountList).length;
  const isInternal = accountList && accountList['*'] && accountList['*'].length > 0;

  const apps = new Set();
  if (isInternal) {
    try {
      const internalAccounts = await apiGetInternalAccounts();
      if (isSuccess(internalAccounts.status)) {
        Object.keys(accountList).forEach(app => apps.add(app.toLowerCase()));
        dispatch({
          type: AUTH_SUCCESS,
          payload: {
            name,
            apps,
            token,
            accounts: indexListBy({ '*': internalAccounts.results }, 'accountId'),
            email,
            isInternal,
            cob_unverified,
          },
        });
        dispatch({ type: DELETE_ALL_MESSAGES, payload: null });
      } else {
        if (isError(internalAccounts.status)) {
          logger.error(
            `${ggDASHConfig.currentUser} status: [${internalAccounts.status}] action.setAccounts Unable to get internal accounts for name:${name} results:${internalAccounts}`
          );
        } else {
          logger.warn(
            `${ggDASHConfig.currentUser} status: [${internalAccounts.status}] action.setAccounts Unable to get internal accounts for name:${name} results:${internalAccounts}`
          );
        }
        dispatch({
          type: SET_MESSAGE,
          payload: { type: 'alert', message: [internalAccounts.results], icon: 'fa-times' },
        });
        history.replace('/');
      }
    } catch (err) {
      logger.error(`${ggDASHConfig.currentUser} action.setAccounts error: ${err}`);
      dispatch({
        type: SET_MESSAGE,
        payload: { type: 'alert', message: ['Problem getting accounts'], icon: 'fa-times' },
      });
    }
  } else {
    // Must be an external user
    Object.keys(accountList).forEach(app => apps.add(app.toLowerCase()));
    dispatch({
      type: AUTH_SUCCESS,
      payload: {
        name,
        apps,
        token,
        accounts: indexListBy(accountList, 'accountId'),
        email,
        cob_unverified,
      },
    });
    dispatch({ type: DELETE_ALL_MESSAGES, payload: null });
  }

  // Redirect users to the URL they were trying to go to before logging in
  const savedURL = localStorage.getItem('savedURL');
  if (savedURL) {
    localStorage.removeItem('savedURL');
    history.push(savedURL);
  }

  // We only want to redirect if the user is logging in
  if (history.location && history.location.pathname === '/login') {
    // Decide where to redirect based on apps
    // send to SSO if user has access to more than one app
    // otherwise send them directly to app they have access to

    let path = '/';
    const key = 'groundportal';

    if (accountAppLength === 1) {
      // take them to SSO view if they are INTERNAL
      if (isInternal) {
        path = `/${ggDASHConfig.apps.sso}`;
        // if they are NOT INTERNAL and have a Connectivity Insights account, redirect them there
      } else if (accountList[key] && accountList[key].length > 0) {
        path = '/dashboard';
        // if they are not INTERNAL and do not have accounts in Connectivity Insights
        // redirect them to the app they have an account with
      } else {
        let app = Object.keys(accountList)[0];
        if (app === 'servicerequest') {
          app = 'service-request';
        }
        if (app === 'onboarding') {
          app = 'customer-onboarding';
        }
        path = `/${app}`;
      }
    } else if (accountAppLength > 1) {
      path = `/${ggDASHConfig.apps.sso}`;
    } else if (cob_unverified) {
      path = `/customer-onboarding`;
    }
    history.push(path);
  }
};

export const logout = () => dispatch => {
  // NOTE: caller is responsible to clear storage (local and session) and changing history.
  // IMPORTANT: used by auth0 login. don't put any history modification or storage removal here
  // as it will screw up the crappy auth0 login procedure. /v
  dispatch({ type: AUTH_LOGOUT, payload: null });
  dispatch({ type: DELETE_ALL_MESSAGES, payload: null });
};

export const resetPassword = email => dispatch => {
  return apiForgotPassword(email)
    .then(resp => {
      if (resp.status === 200) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            type: 'message',
            message: [`We've sent a password reset email to ${email}.`],
            icon: 'fa-check',
          },
        });
      } else {
        logger.error(
          `${email} status: [${resp.status}] Problem resetting password for: [${email}]`
        );
        dispatch({
          type: SET_MESSAGE,
          payload: {
            type: 'alert',
            status: resp.status,
            message: [`Problem resetting password for ${email}`],
            icon: 'fa-times',
          },
        });
      }
    })
    .catch(ex => {
      logger.error(`${ggDASHConfig.currentUser} action.resetPassword resetPassword(): ${ex}`);
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: 'alert',
          message: [`Problem resetting password for ${email}`],
          icon: 'fa-times',
        },
      });
    });
};

export const loadAircraftForAccount = accountId => async dispatch => {
  logger.log(
    `${ggDASHConfig.currentUser} loadAircraftForAccount loading using accountId: ${accountId}`
  );
  const timer = setTimeout(() => {
    dispatch({ type: LOADING_UI, payload: true });
  }, 1000);

  const clearLoading = () => {
    clearTimeout(timer);
    dispatch({ type: LOADING_UI, payload: false });
  };

  try {
    // clear old aircraft
    dispatch({ type: SET_AIRCRAFT, payload: [] });

    const data = await apiGetAircraft(accountId);
    if (isSuccess(data.status)) {
      clearLoading();
      const result = indexListBy(data.results, 'id');
      dispatch({ type: SET_AIRCRAFT, payload: result });
      dispatch({ type: SET_SELECTED_ACCOUNT, payload: { accountId } });
    } else {
      clearLoading();
      if (isError(data.status)) {
        logger.error(
          `${ggDASHConfig.currentUser} status: [${data.status}] Problem loading aircraft for account: [${accountId}]`
        );
      } else {
        logger.warn(
          `${ggDASHConfig.currentUser} status: [${data.status}] Problem loading aircraft for account: [${accountId}]`
        );
      }
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: 'alert',
          status: data.status,
          message: [`Problem loading account: ${accountId}`],
          icon: 'fa-times',
        },
      });
    }
  } catch (ex) {
    clearLoading();
    logger.error(`${ggDASHConfig.currentUser} action.loadAircraftForAccount ex: ${ex}`);
    dispatch({
      type: SET_MESSAGE,
      payload: { type: 'alert', message: ['Problem getting accounts'], icon: 'fa-times' },
    });
  }
};

export const loadAircraftSummaryForAccount = accountId => async dispatch => {
  const timer = setTimeout(() => {
    dispatch({ type: LOADING_UI, payload: true });
  }, 1000);

  const clearLoading = () => {
    clearTimeout(timer);
    dispatch({ type: LOADING_UI, payload: false });
  };
  try {
    // clear old flights
    dispatch({ type: SET_AIRCRAFT, payload: [] });
    const aircraftData = await apiGetAircraft(accountId);
    const aircraftViewData = await apiGetAircraftView(accountId);
    if (isSuccess(aircraftData.status) && isSuccess(aircraftViewData.status)) {
      clearLoading();
      const aircraftDataResult = indexListBy(aircraftData.results, 'id');
      const aircraftDataViewResult = indexListBy(aircraftViewData.results, 'aircraft_id');
      const resultObj = { ...aircraftDataResult[1], ...aircraftDataViewResult[1] };
      const resultArr = [...new Set([...aircraftDataResult[0], ...aircraftDataViewResult[0]])];
      const result = [resultArr, resultObj];
      dispatch({ type: SET_AIRCRAFT, payload: result });
    } else {
      clearLoading();
      if (isError(aircraftData.status)) {
        logger.error(
          `${ggDASHConfig.currentUser} status: [${aircraftData.status}] Problem loading aircraftSummary for account: [${accountId}]`
        );
      } else {
        logger.warn(
          `${ggDASHConfig.currentUser} status: [${aircraftData.status}] Problem loading aircraftSummary for account: [${accountId}]`
        );
      }
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: 'alert',
          status: aircraftData.status,
          message: [`Problem loading account: ${accountId}`],
          icon: 'fa-times',
        },
      });
    }
  } catch (ex) {
    clearLoading();
    logger.error(`${ggDASHConfig.currentUser} action.loadAircraftSummaryForAccount ex: ${ex}`);

    dispatch({
      type: SET_MESSAGE,
      payload: { type: 'alert', message: ['Problem getting accounts'], icon: 'fa-times' },
    });
  }
};
